import React from 'react';

const Intro = ({ intro }) => {
    return (
        <>
            <section className="ld--intro ld--card">
                <h2 className="ld--title">Lo que se permite, se repite<br></br></h2>
                {intro}
            </section>
        </>
    );
};

export default Intro;
