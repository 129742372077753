import React from 'react';
import moment from 'moment';
moment.locale('es');
const Precios = ({ precios, mapaUrl, fees }) => {
    return (
        <section className='ld--wrap-precios'>
            <div className='ld--precios'>
                {mapaUrl && (
                    <div className='ld--mapa ld--card'>
                        <figure>
                            <img
                                src={mapaUrl}
                                alt='mapa precios'
                            />
                        </figure>
                    </div>
                )}
                <div className='ld--detalles mt-4'>
                    {precios.map((p, idx) => (
                        <div className='ld--item-precio' key={idx}>
                            <div className='ld--info'>
                                {typeof p.color === 'string' ? (
                                    <span
                                        className='ld--color'
                                        style={{ backgroundColor: p.color }}
                                    ></span>
                                ) : (p.color.map(color => (
                                    <span
                                        key={color}
                                        className='ld--color'
                                        style={{ backgroundColor: color }}
                                    ></span>
                                )))}
                                <div className='ld--nombre'>{p.nombre}</div>
                                <div className='ld--descripcion'>
                                    {p.descripcion}
                                </div>
                            </div>
                            <div className='ld--precio'>{p.precio}</div>
                        </div>
                    ))}
                    <div className='ld--nota-fees'>
                        {fees ? "Los precios incluyen cargos por servicio." : "Los precios no incluyen cargos por servicio."}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Precios;
